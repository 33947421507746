<template>
  <div>
    <div class="mockups">
      <a-page-header title="Product details" @back="goCatalog">
        <div style="position: absolute; top: 13px; right: 7px;">
          <p style="margin: 0">
            Last modified at {{ customProductInfo.editDate }}
          </p>
          <p style="margin: 0">Created at {{ customProductInfo.addDate }}</p>
        </div>
      </a-page-header>
      <div class="container">
        <h4 class="ellipsis">{{ customProductInfo.title }}</h4>
        <a-tabs v-model="activeKey" type="card" @change="callback">
          <!-------------------------------------------------------Mockups  -------------------------------------------------------------------------------->
          <a-tab-pane :key="1" tab="1. Mockups">
            <div class="mockup">
              <div class="content">
                <div class="left">
                  <div class="left-img">
                    <img :src="mainImg">
                    <!-- <p>Edit design</p> -->
                  </div>
                </div>
                <div class="right">
                  <div class="top-title">Select the title image view</div>
                  <div v-if="CurrentShop && (CurrentShop.shopType == 5)" class="top-title2">Choose a title image and select mockup views to display in your store.(Up to 10 pictures)</div>
                  <ul>
                    <li v-for="(item, index) in images" :key="index">
                      <!-- radio等于1选中第一个 -->
                      <a-radio-group
                        v-model="radio"
                        @change="changeImg(item.url, item.id)"
                      >
                        <a-radio :value="index">
                          <span />
                        </a-radio>
                      </a-radio-group>
                      <img :src="item.url" :class="radio == index ? 'list-img-checked' : 'list-img'">
                      <div v-show="CurrentShop && (CurrentShop.shopType == 5)">
                        <div v-if="!item.isChecked" class="addview" @click="handChecked(item,index,'checked')">
                          <img src="@/assets/images/custom/check.svg" alt="">
                          <span>Add View</span>
                        </div>
                        <div v-if="item.isChecked == 'checked'" class="addview" @click="handChecked(item,index,'')">
                          <img src="@/assets/images/custom/checked.svg" alt="">
                          <span>Add View</span>
                        </div>
                        <div v-if="item.isChecked == 'disabled'" class="addview">
                          <img src="@/assets/images/custom/disabled-checked.svg" alt="">
                          <span>Add View</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div style="text-align:right">
                <a-button type="primary" size="large" @click="next(2)">
                  Next
                  <a-icon type="right" />
                </a-button>
              </div>
            </div>
          </a-tab-pane>
          <!-------------------------------------------------------Mockups  END  -------------------------------------------------------------------------------->
          <!-------------------------------------------------------description  -------------------------------------------------------------------------------->
          <a-tab-pane :key="2" tab="2. Description" :force-render="true">
            <div class="description">
              <div class="content">
                <div class="left">
                  <div class="left-img">
                    <img :src="mainImg">
                  </div>
                </div>
                <div class="right">
                  <div class="top-title">Product title</div>
                  <input v-model="productTitle" type="text">
                  <div class="top-title">Product description</div>
                  <div v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)">
                    <a-textarea
                      v-model="description"
                      :auto-size="{ minRows: 7 }"
                      :max-length="500"
                    />
                    <!--                    <div class="descrip" v-html="description" />-->
                  </div>
                  <div v-show="CurrentShop && (CurrentShop.shopType !== enums.shopTypeEnum.ETSY.value)">
                    <div id="editor" ref="editor" class="editor" />
                    <a-checkbox
                      v-model="checked"
                    >Add a size chart into description table to
                      description</a-checkbox>
                    <div v-show="isShowRadioEdit" class="isShowRadioEdit">
                      <a-radio-group v-model="radioEditor">
                        <a-radio :value="1">Imperial</a-radio>
                        <a-radio :value="2">Metric</a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                  <div v-if="isSupportPersonlizationSetting && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value)">
                    <div class="top-title">Personlization setting</div>
                    <a-checkbox
                      v-model="personlizationSettingEnable"
                    >Allow your customers to enter their own custom name for this product in your store.</a-checkbox>
                    <div v-if="personlizationSettingEnable">
                      <div class="top-title">
                        Option title
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span class="info-title">{{ personlizationSettingName ? personlizationSettingName:'Custom Name' }} (Up to {{ personlizationSettingMaxlength ? personlizationSettingMaxlength: 10 }} characters)</span>
                            <input :readonly="true" disabled style="border: 1px solid #ddd; width: 260px;height: 30px">
                          </template>
                          <a-icon type="info-circle" />
                        </a-tooltip>
                      </div>
                      <input v-model="personlizationSettingName" type="text">
                      <div class="top-title">
                        Characters quantity
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span class="info-title">{{ personlizationSettingName ? personlizationSettingName:'Custom Name' }} (Up to {{ personlizationSettingMaxlength ? personlizationSettingMaxlength: 10 }} characters)</span>
                            <input :readonly="true" disabled style="border: 1px solid #ddd; width: 260px;height: 30px">
                          </template>
                          <a-icon type="info-circle" />
                        </a-tooltip>
                      </div>
                      <a-input-number v-model="personlizationSettingMaxlength" class="inputNumber" :min="1" :max="50" type="text" />
                    </div>
                  </div>
                  <!-- <div v-if="CurrentShop&&CurrentShop.shopType == 1 && enums.blankProductTypeEnum.SIMPLE.value == productType" class="vendor">
                    <span>Vendor:</span>
                    <input v-model="vendor" type="type">
                  </div> -->
                  <div class="tags">
                    <p class="tag-title">Tags
                      <a-tooltip placement="topLeft">
                        <template #title>
                          <span>You can add up to 13 tags, each of up to 20 characters.</span>
                        </template>
                        <img src="@/assets/images/custom/form-tishi.svg" alt="">
                      </a-tooltip> </p>
                    <div>
                      <template v-for="tag in tags">
                        <a-tooltip
                          v-if="tag.length > 20"
                          :key="tag"
                          :title="tag"
                        >
                          <a-tag
                            :key="tag"
                            style="margin-top: 8px"
                            closable
                            @close="() => handleClose(tag)"
                          >
                            {{ `${tag.slice(0, 20)}...` }}
                          </a-tag>
                        </a-tooltip>
                        <a-tag
                          v-else
                          :key="tag"
                          style="margin-top: 8px"
                          closable
                          @close="() => handleClose(tag)"
                        >
                          {{ tag }}
                        </a-tag>
                      </template>
                    </div>
                    <div>
                      <input
                        v-model="tag"
                        type="text"
                        placeholder="Add a tag"
                        @keydown="addTag"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!--              <div v-if="fittingsIds.length > 0 || (CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value || CurrentShop.shopType === enums.shopTypeEnum.ETSY.value))" style="text-align:right">
                <a-button type="primary" size="large" @click="next(3)">
                  Next
                  <a-icon type="right" />
                </a-button>
              </div>-->
              <div style="text-align:right">
                <a-button type="primary" size="large" @click="next(3)">
                  Next
                  <a-icon type="right" />
                </a-button>
              </div>
            </div>
          </a-tab-pane>
          <!-------------------------------------------------------  description  END  -------------------------------------------------------------------------------->
          <a-tab-pane v-if="fittingsIds.length > 0" :key="3" tab="3.Branding">
            <branding :fittings="fittings" :product-type="productType" @next="next" @refresh="oninit" />
          </a-tab-pane>
          <!-------------------------------------------------------  Variants  -------------------------------------------------------------------------------->
          <a-tab-pane v-if="CurrentShop" :key="fittingsIds.length > 0 ? 4 : 3" :tab="fittingsIds.length > 0 ? '4. Variants' : '3. Variants'">
            <div class="variants">
              <div class="left">
                <div class="left-img">
                  <img :src="mainImg">
                  <!-- <p>Edit design</p> -->
                </div>
              </div>
              <div class="right">
                <div class="title">Pricing</div>
                <div class="table">
                  <div class="thead clearfix">
                    <div class="tr">Size</div>
                    <div class="tr">Price,$</div>
                    <div class="tr">Retail price, $(USD)</div>
                    <div class="tr">Compare at price</div>
                    <div class="tr">Profit, $(USD)</div>
                    <div class="tr">Profit, %</div>
                  </div>
                  <div class="tbody">
                    <div
                      v-for="(item, index) in variant"
                      :key="index"
                      class="tfirst"
                      :class="{ tbup: item.isUp == true }"
                    >
                      <div
                        class="tr clearfix"
                        :class="{ tbActive: item.isUp == false }"
                        @click.stop="downUp(index)"
                      >
                        <div class="td firstSku" style="cursor: pointer;">
                          <a-icon
                            v-if="item.sku2.length > 0"
                            type="caret-down"
                          />
                          {{ item.name }}
                        </div>
                        <a-tooltip placement="top">
                          <template slot="title">
                            <span>Cost For Additional Items</span>
                          </template>
                          <div class="td">{{ item.costs }} <span v-if="item.fittingsPrice > 0" style="color: #FE4010">+{{ item.fittingsPrice }}</span> </div>
                        </a-tooltip>
                        <div class="td" @click.stop="stopClick">
                          <a-input-number
                            v-model="item.retailPrice"
                            :min="0.1"
                            :max="1000000"
                            @change="changeRetail(index, $event)"
                          />
                          <!-- <button class="del" @click.stop="delRetail(index)">-</button>
                          <button class="add" @click.stop="addRetail(index)">+</button>-->
                        </div>
                        <div class="td" @click.stop="stopClick">
                          <a-input-number
                            v-model="item.compareAtPrice"
                            :min="0.1"
                            :max="1000000"
                            @change="changeCompare(index, $event)"
                          />
                        </div>
                        <div class="td">{{ item.profit | MoneyFormat }}</div>
                        <div class="td">{{ item.profit2 | RateFormat }}%</div>
                      </div>
                      <div class="node-title">{{ item.skuName }}</div>
                      <div
                        v-for="(item2, index2) in item.sku2"
                        :key="index2"
                        class="tfirst tbActive two"
                      >
                        <div class="tr tbActive clearfix">
                          <div class="td" @click="downUp2(index, index2)">
                            <a-icon
                              v-if="item2.skuthree.length > 0"
                              type="caret-down"
                            />
                            {{ item2.name }}
                          </div>
                          <div class="td">{{ item2.costs }}</div>
                          <div class="td" @click.stop="stopClick">
                            <a-input-number
                              v-model="item2.retailPrice"
                              :min="1"
                              :max="1000000"
                              @change="changeRetail2(index, index2, $event)"
                            />
                          </div>
                          <div class="td" @click.stop="stopClick">
                            <a-input-number
                              v-model="item2.compareAtPrice"
                              :min="1"
                              :max="1000000"
                              @change="changeCompare2(index, index2, $event)"
                            />
                          </div>
                          <div class="td">{{ item2.profit | MoneyFormat }}</div>
                          <div class="td">
                            {{ item2.profit2 | RateFormat }}%
                          </div>
                        </div>
                        <div
                          v-show="item2.isUp == true"
                          class="node-title pl20"
                        >
                          {{ item2.skuName }}
                        </div>
                        <div
                          v-for="(item3, index3) in item2.skuthree"
                          v-show="item2.isUp == true"
                          :key="index3 + 's'"
                          class="tfirst tbActive two mar0"
                        >
                          <div class="tr tbActive clearfix">
                            <div class="td pl20">{{ item3.name }}</div>
                            <div class="td">{{ item3.costs }}</div>
                            <div class="td">
                              <a-input-number
                                v-model="item3.retailPrice"
                                :min="1"
                                :max="1000000"
                                @change="
                                  changeRetail3(index, index2, index3, $event)
                                "
                              />
                            </div>
                            <div class="td">
                              <a-input-number
                                v-model="item3.compareAtPrice"
                                :min="1"
                                :max="1000000"
                                @change="
                                  changeCompare3(index, index2, index3, $event)
                                "
                              />
                            </div>
                            <div class="td">
                              {{ item3.profit | MoneyFormat }}
                            </div>
                            <div class="td">
                              {{ item3.profit2 | RateFormat }}%
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)">
                  <div class="delivery-title">Delivery Option
                    <a-tooltip placement="topLeft">
                      <template #title>
                        <span>Select a delivery option for this product. You can also visit your store settings to choose a default method for assigning delivery options.</span>
                      </template>
                      <img src="@/assets/images/custom/form-tishi.svg" alt="">
                    </a-tooltip>
                  </div>
                  <form-select v-model="serverLogisticsMethodId" :options="serverLogisticsMethods" :is-show-select-error="isShowSelectError" :select-error-text="selectErrorText" label-key="name" value-key="id" />
                  <div class="delivery-title">Shipping Methods
                    <a-tooltip placement="topLeft">
                      <template #title>
                        <span>The selected shipping method is synchronized with your JetPrint account after placing orders. You can also manually change the shipping method. Additionally, selecting the shipping method here will not impact your shipping profiles.</span>
                      </template>
                      <img src="@/assets/images/custom/form-tishi.svg" alt="">
                    </a-tooltip>
                  </div>
                  <form-desc-radio v-model="logisticsMethodId" :options="logisticsMethods" label-key="name" value-key="id" />
                </div>
              </div>
            </div>
            <div style="text-align:right">
              <a-button v-if="fittingsIds.length === 0" type="primary" size="large" @click="next(4)">
                Next
                <a-icon type="right" />
              </a-button>
              <a-button v-else type="primary" size="large" @click="next(5)">
                Next
                <a-icon type="right" />
              </a-button>
            </div>
          </a-tab-pane>
          <!-------------------------------------------------------  Variants  END  -------------------------------------------------------------------------------->
          <a-tab-pane
            v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value || CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)"
            :key="fittingsIds.length > 0 ? 5 : 4"
            :tab="fittingsIds.length > 0 ? '5. Store details': '4. Store details'"
          >
            <div class="store-detail">
              <div class="content">
                <div class="left">
                  <div class="left-img">
                    <img :src="mainImg">
                  </div>
                </div>
                <div class="right">
                  <div class="top-title">Publishing</div>
                  <!-- <div class="error">
                    <a-icon type="info-circle" />This product contains average
                    resolution layers that might affect the print quality on
                    your product.
                  </div>-->
                  <div v-if="CurrentShop && CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value" class="store-print">
                    <a-checkbox
                      v-model="pushShopCheck"
                    >Publish product to your Shopify store</a-checkbox>
                  </div>
                  <div v-if="CurrentShop && CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value" class="store-print">
                    <a-checkbox
                      v-model="pushShopCheck"
                    >Publish product to your woocommerce store</a-checkbox>
                  </div>
                  <div v-if="CurrentShop && CurrentShop.shopType === enums.shopTypeEnum.ETSY.value" class="store-print">
                    <a-checkbox
                      v-model="pushShopCheck"
                    >Publish product to your Etsy store</a-checkbox>
                  </div>
                  <div
                    v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value)
                    "
                    class="store-print"
                  >
                    <a-checkbox v-model="hideStore">Hide in store</a-checkbox>
                  </div>
                  <div
                    v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)
                    "
                    class="store-print"
                  >
                    <a-checkbox v-model="hideStore">Save to your draft</a-checkbox>
                  </div>
                  <div v-if="CurrentShop&&CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value" class="vendor">
                    <span>Vendor:</span>
                    <input v-model="vendor">
                  </div>
                  <div
                    v-if="CurrentShop&&(CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value)"
                    class="vendor"
                  >
                    <span
                      v-if="CurrentShop && CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value"
                    >Shopify collections:</span>
                    <span
                      v-if="CurrentShop && CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value"
                    >woocommerce collections:</span>
                    <a-select placeholder="Select" @change="collectChange">
                      <a-select-option
                        v-for="item in collectList"
                        :key="item.id"
                        :value="item.id"
                      >{{ item.title }}</a-select-option>
                    </a-select>
                  </div>
                  <div v-if="isSupportPersonlizationSetting && (CurrentShop && CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)">
                    <div class="delivery-title">Personalization</div>
                    <div
                      class="store-print"
                    >
                      <a-checkbox v-model="personlizationSettingEnable">Personalization is optional</a-checkbox>
                    </div>
                  </div>
                  <div class="btn">
                    <!-- <a-button type="primary" @click="save(0)">Save as draft</a-button> -->
                    <a-button
                      v-if="
                        CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value || CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)
                      "
                      style="margin-left: 20px"
                      type="primary"
                      @click="save(1)"
                    >Publish</a-button>
                  </div>
                </div>
                <!-- <div v-if="isSupportPersonlizationSetting">
                  <div
                    v-if="CurrentShop && (CurrentShop.shopType == 1 || CurrentShop.shopType == 5)
                    "
                    class="store-print"
                  >
                    <a-checkbox v-model="personlizationSettingEnable">Personalization is optional</a-checkbox>
                  </div>
                </div> -->
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import FormSelect from '@/components/form/formSelect'
import FormDescRadio from '@/components/form/formDescRadio'
import E from 'wangeditor'
import { mapState, mapMutations } from 'vuex'
// import E from "wangeditor-antd";
import {
  generateDetail,
  productDetail,
  customCollectionList,
  serverLogisticsMethods,
  productModify
} from '@/api/product'
import { logisticsMethods } from '@/api/public'
import blankProductTypeEnum from '@/enums/blank_product/blankProductTypeEnum'
import blankProductTypeCategoryEnum from '@/enums/blank_product/blankProductTypeCategoryEnum'
import shopTypeEnum from '@/enums/shopTypeEnum'
import Branding from './components/Branding'
export default {
  components: {
    FormSelect,
    FormDescRadio,
    Branding
  },
  data() {
    return {
      enums: {
        blankProductTypeEnum,
        blankProductTypeCategoryEnum,
        shopTypeEnum
      },
      activeKey: 1, // 当选选中第几个tabs
      fullscreenLoading: false, // Loading...
      customProductId: 0, // 定制产品ID
      mainImageId: 0, //* 选中的主图ID,mockups页面那个
      shopifyCollectionId: 0, // shopify收藏夹ID
      productTitle: '', // description 的title
      description: null,
      tags: [],
      isShowRadioEdit: true,
      radioEditor: 1, // 富文本Imperial  metric
      checked: true,
      activeName: 'mockup',
      customProductInfo: {},
      images: [],
      radio: 0,
      mainImg: '', // 当前主图显示的图片
      editor: '<p>ffdsfsd</p>', // 存放实例化的wangEditor对象，在多个方法中使用
      blankDescToShopify: '',
      blankSpecMetric: '',
      tag: '', // 文本框里的tag
      variantSkuName: '', // variant标题名称
      variant: [],
      pushShopCheck: true, // 是否推送到shopify
      hideStore: false,
      vendor: '', // 供应商
      collect: '', // 产品收藏夹
      collectList: [], // 产品收藏夹列表,
      serverLogisticsMethods: [], // 第三方配送方式
      logisticsMethods: [], // 配送方式
      productType: null,
      isSupportPersonlizationSetting: null,
      personlizationSettingEnable: false,
      personlizationSettingMaxlength: '',
      personlizationSettingName: '',
      logisticsMethodId: null,
      serverLogisticsMethodId: null,

      fittings: [],
      fittingsIds: [],
      isShowSelectError: false,
      selectErrorText: ''
    }
  },
  computed: {
    ...mapState({
      CurrentShop: state => state.User.merchantInfo.currentShop
    }),
    getFittingPrice() {
      let num = 0
      this.fittings.forEach((item, index) => {
        num += item.price
      })
      return num
    }
  },
  watch: {
    checked(val) {
      let html
      html = this.editor.txt.html()
      html = html.replace(this.blankSpecImperial, '').replace(this.blankSpecMetric, '')
      if (val) {
        if (this.radioEditor === 1) {
          html = this.blankSpecImperial + html
        } else {
          html = this.blankSpecMetric + html
        }
        this.radioEditor = 1
        this.editor.txt.html(html)
        this.isShowRadioEdit = true
      } else {
        this.isShowRadioEdit = false
        this.editor.txt.html(html)
      }
    },
    radioEditor(val) {
      let html
      html = this.editor.txt.html()
      html = html.replace(this.blankSpecImperial, '').replace(this.blankSpecMetric, '')

      if (val === 1) {
        this.editor.txt.html(this.blankSpecImperial + html)
      } else {
        this.editor.txt.html(this.blankSpecMetric + html)
      }
    }
  },
  async mounted() {
    // wangeditor
    this.editor = new E('#editor')
    this.editor.customConfig.lang = {
      设置标题: 'title',
      正文: 'p',
      链接文字: 'link text',
      链接: 'link',
      上传图片: 'upload image',
      上传: 'upload',
      创建: 'create',
      字号: 'font size',
      字体: 'font',
      文字颜色: 'text color',
      设置列表: 'list',
      有序列表: 'ordered list',
      无序列表: 'unordered list',
      对齐方式: 'alignment',
      靠左: 'keep left',
      靠右: 'keep right',
      居中: 'centered',
      插入代码: 'insert code',
      插入表格: 'insert table',
      插入: 'insert',
      行: 'row',
      列的表格: 'list table'
      // 还可自定添加更多
    }
    this.editor.customConfig.menus = [
      'head', // 标题
      'bold', // 粗体
      'fontSize', // 字号
      'fontName', // 字体
      'italic', // 斜体
      'underline', // 下划线
      'strikeThrough', // 删除线
      'foreColor', // 文字颜色
      // 'backColor',  // 背景颜色
      'link', // 插入链接
      'list', // 列表
      'justify', // 对齐方式
      'quote', // 引用
      // 'emoticon',  // 表情
      // 'image',  // 插入图片
      'table', // 表格
      // 'video',  // 插入视频
      'code', // 插入代码
      'undo' // 撤销
      // 'redo'  // 重复
    ]
    this.editor.create() // 以上配置完成之后调用其create()方法进行创建

    // 点击事件，拿到富文本编辑器里面的值

    // const id = this.$route.query.customProductId
    // const productId = this.$route.query.productId
    this.getCustomCollectionList()
    this.getServerLogisticsMethods()
    this.getLogisticsMethods()
    this.pageData()
  },
  methods: {
    ...mapMutations('User', ['IsPushProductmutations']),
    oninit() {
      this.images = []
      this.getCustomCollectionList()
      this.getServerLogisticsMethods()
      this.getLogisticsMethods()
      this.pageData()
    },
    pageData() {
      const _this = this
      Promise.all([
        _this.getproductDetail(this.$route.query.productId),
        _this.generateDetail(this.$route.query.customProductId)
      ]).then(values => {
        this.customProductInfo = values[1].customProductInfo
        this.customProductId = values[1].customProductInfo.id
        if (values[1].fittings) {
          this.fittings = values[1].fittings
        }
        let num = 0
        for (let i = 0; i < values[1].images.length; i++) {
          if (values[1].images[i]) {
            num = num + 1
          }
        }
        for (let i = 0; i < values[1].images.length; i++) {
          if (values[1].images[i].checked) {
            values[1].images[i].isChecked = 'checked'
          } else {
            if (num === 10) {
              values[1].images[i].isChecked = 'disabled'
            } else {
              values[1].images[i].isChecked = ''
            }
          }
          this.images.push(values[1].images[i])
        }
        this.hideStore = this.customProductInfo.isHiddenInStore
        this.mainImageId = values[1].images[0].id
        this.mainImg = values[1].images[0].url
        this.radio = 0
        for (let m = 0; m < values[1].images.length; m++) {
          if (values[1].images[m].id === values[1].customProductInfo.mainImageId) {
            this.mainImg = values[1].images[m].url
            this.mainImageId = values[1].images[m].id
            this.radio = m
            break
          }
        }

        this.productTitle = values[1].customProductInfo.title
        this.description = values[1].customProductInfo.description
        this.personlizationSettingEnable = values[1].customProductInfo.personlizationSettingEnable
        this.isSupportPersonlizationSetting = values[1].customProductInfo.isSupportPersonlizationSetting
        this.personlizationSettingMaxlength = values[1].customProductInfo.personlizationSettingMaxlength || 10
        this.personlizationSettingName = values[1].customProductInfo.personlizationSettingName || 'Custom Name'
        if (values[1].customProductInfo.logisticsMethodId) {
          this.logisticsMethodId = values[1].customProductInfo.logisticsMethodId
        }
        this.serverLogisticsMethodId = values[1].customProductInfo.serverLogisticsMethodId
        if (values[1].customProductInfo.isAddMertic === true) {
          this.radioEditor = 2
        } else {
          this.radioEditor = 1
        }
        this.editor.txt.html(this.description)
        const variantList = values[1].variants
        this.variant = []
        const list = []
        const array = []
        variantList.map(item => {
          if (array.indexOf(item.option1Value) === -1) {
            const param = {}
            param.name = item.option1Value
            param.costs = item.costs
            if (!item.option2Value) {
              param.id = item.id
            }
            param.compareAtPrice = item.compareAtPrice
            param.isUp = true
            param.profit = item.retailPrice - item.costs
            param.profit2 = (item.retailPrice - item.costs) / item.retailPrice
            param.retailPrice = item.retailPrice
            param.fittingsPrice = item.fittingsPrice
            param.sku2 = []
            list.push(param)
            array.push(item.option1Value)
          }
        })
        list.map(item => {
          variantList.map(items => {
            if (items.option2Value) {
              if (item.name === items.option1Value) {
                let num = 0
                for (let i = 0; i < item.sku2.length; i++) {
                  if (items.option2Value === item.sku2[i].name) {
                    num = num + 1
                  }
                }
                if (num === 0) {
                  const param = {}
                  if (!items.option3Value) {
                    param.id = items.id
                  }
                  param.name1 = items.option1Value
                  param.name = items.option2Value
                  param.compareAtPrice = items.compareAtPrice
                  param.costs = items.costs
                  param.fittingsPrice = item.fittingsPrice
                  if (items.option3Value) {
                    param.isUp = false
                  }
                  param.profit = items.retailPrice - items.costs
                  param.profit2 =
                  (items.retailPrice - items.costs) / items.retailPrice
                  param.retailPrice = items.retailPrice
                  if (items.option3Name) {
                    param.skuName = items.option3Name
                  }
                  param.skuthree = []
                  item.sku2.push(param)
                }
              }
            }
          })
        })
        for (let i = 0; i < list.length; i++) {
          list[i]['sku2'].map(item => {
            variantList.map(items => {
              if (items.option2Value && items.option3Value) {
                if (
                  item.name === items.option2Value &&
                item.name1 === items.option1Value
                ) {
                  const param = {}
                  param.id = items.id
                  param.name = items.option3Value
                  param.compareAtPrice = items.compareAtPrice
                  param.costs = items.costs
                  param.retailPrice = items.retailPrice
                  param.profit = items.retailPrice - items.costs
                  param.fittingsPrice = item.fittingsPrice
                  param.profit2 =
                  (items.retailPrice - items.costs) / items.retailPrice
                  param.skuName = items.option3Name

                  item.skuthree.push(param)
                }
              }
            })
          })
        }
        this.variant = list
        console.log(list)
        this.tags = values[1].customProductInfo.tags.split(',')
      })
    },
    handChecked(data, index, val) {
      this.$set(this.images[index], 'isChecked', val)
      let num = 0
      this.images.forEach((item, itemIndex) => {
        if (item.isChecked == 'checked') {
          num = num + 1
        }
      })
      if (num >= 10) {
        this.images.forEach((item, itemIndex) => {
          if (!item['isChecked']) {
            this.$set(this.images[itemIndex], 'isChecked', 'disabled')
          }
        })
      } else {
        this.images.forEach((item, itemIndex) => {
          if (item['isChecked'] === 'disabled') {
            this.$set(this.images[itemIndex], 'isChecked', '')
          }
        })
      }
    },
    handleClose(removedTag) {
      const tags = this.tags.filter(tag => tag !== removedTag)
      console.log(tags)
      this.tags = tags
    },
    async generateDetail(id) {
      const { data } = await generateDetail({ customProductId: id })
      return data
    },
    collectChange(value) {
      this.collect = value
    },
    next(val) {
      debugger
      if (this.CurrentShop && (this.CurrentShop.shopType === this.enums.shopTypeEnum.ETSY.value) && !this.serverLogisticsMethodId && val === 4) {
        this.isShowSelectError = true
        this.selectErrorText = 'This must be selected'
        this.$message.error('Delivery Option must be selected ！')
        return
      }
      this.isShowSelectError = false
      this.selectErrorText = ''
      this.activeKey = val
      // todo 正式上val===4  测试上val===5
      // 有附件
      // v-if="CurrentShop && (CurrentShop.shopType === enums.shopTypeEnum.SHOPIFY.value || CurrentShop.shopType === enums.shopTypeEnum.WOOCOMMERCE.value || CurrentShop.shopType === enums.shopTypeEnum.ETSY.value)"
      if (this.fittingsIds.length > 0) {
        if (val === 4 && (this.CurrentShop.shopType === this.enums.shopTypeEnum.SHOPIFY.value || this.CurrentShop.shopType === this.enums.shopTypeEnum.WOOCOMMERCE.value || this.CurrentShop.shopType === this.enums.shopTypeEnum.ETSY.value)) {
          this.save(0)
        }
        if ((val === 5) &&
            (this.CurrentShop.shopType === this.enums.shopTypeEnum.GENERAL.value || this.CurrentShop.shopType === this.enums.shopTypeEnum.NOT_BIND.value)) {
          this.save(0)
        }
      } else {
        // 没附件
        if ((val === 4) &&
            (this.CurrentShop.shopType === this.enums.shopTypeEnum.GENERAL.value || this.CurrentShop.shopType === this.enums.shopTypeEnum.NOT_BIND.value)) {
          this.save(0)
        }
      }
    },
    // 获取产品收藏夹
    async getCustomCollectionList() {
      const { data } = await customCollectionList()
      this.collectList = data['items']
    },
    async getServerLogisticsMethods() {
      const { data } = await serverLogisticsMethods()
      this.serverLogisticsMethods = data['itemList']
    },
    async getLogisticsMethods() {
      const { data } = await logisticsMethods()
      this.logisticsMethods = data['itemList']
      this.logisticsMethodId = data['itemList'][0].id
    },
    // 获取产品详情
    async getproductDetail(id) {
      const { data } = await productDetail({
        id: id,
        blankProductInfo: true,
        blankDescToShopify: true,
        blankSpecMetric: true,
        blankSpecImperial: true
      })
      this.blankSpecMetric = data.blankSpecMetric
      this.blankSpecImperial = data.blankSpecImperial
      this.blankDescToShopify = data.blankDescToShopify
      this.productType = data.blankProductInfo.type
      if (data.blankProductInfo.fittingsIds) {
        this.fittingsIds = data.blankProductInfo.fittingsIds
      }
      return data
    },
    // 切换标题
    callback(key) {
      console.log(key)
      // const id = event.target.id.split("-");
      // this.activeName = id[1];
    },
    // mockup选择图片
    changeImg(url, id) {
      this.mainImageId = id
      this.mainImg = url
    },
    addTag(e) {
      var theEvent = window.event || e
      var code = theEvent.keyCode || theEvent.which || theEvent.charCode
      if (code === 13) {
        // 键盘回车事件
        this.tags.push(this.tag)
        this.tag = ''
      }
    },
    // variant点击展开
    downUp(index) {
      if (this.variant[index].isUp === false) {
        this.variant[index].isUp = true
      } else {
        this.variant[index].isUp = false
      }
    },
    downUp2(index, index2) {
      if (this.variant[index]['sku2'][index2]['isUp'] === false) {
        this.variant[index]['sku2'][index2]['isUp'] = true
      } else {
        this.variant[index]['sku2'][index2]['isUp'] = false
      }
    },
    changeRetail(index, val) {
      this.variant[index]['retailPrice'] = val // 零售价
      this.variant[index]['profit'] =
        this.variant[index]['retailPrice'] - this.variant[index]['costs'] // profit利润
      const profit2 =
        this.variant[index]['profit'] / this.variant[index]['retailPrice']
      this.variant[index]['profit2'] = profit2.toFixed(1)

      for (let j = 0; j < this.variant[index]['sku2'].length; j++) {
        this.variant[index]['sku2'][j].retailPrice = this.variant[
          index
        ].retailPrice
        this.variant[index]['sku2'][j]['profit'] = this.variant[index][
          'profit'
        ]
        this.variant[index]['sku2'][j]['profit2'] = this.variant[index][
          'profit2'
        ]
        for (
          let i = 0;
          i < this.variant[index]['sku2'][j]['skuthree'].length;
          i++
        ) {
          this.variant[index]['sku2'][j]['skuthree'][i][
            'retailPrice'
          ] = this.variant[index].retailPrice
          this.variant[index]['sku2'][j]['skuthree'][i][
            'profit'
          ] = this.variant[index]['profit']
          this.variant[index]['sku2'][j]['skuthree'][i][
            'profit2'
          ] = this.variant[index]['profit2']
        }
      }
    },
    // 第二层的Retail
    changeRetail2(index, index2, val) {
      this.variant[index]['sku2'][index2]['retailPrice'] = val // 零售价
      this.variant[index]['sku2'][index2]['profit'] =
        this.variant[index]['sku2'][index2]['retailPrice'] -
        this.variant[index]['sku2'][index2]['costs'] // profit利润
      const profit2 = this.variant[index]['sku2'][index2]['profit'] / val
      this.variant[index]['sku2'][index2]['profit2'] = profit2.toFixed(1)
      for (
        let i = 0;
        i < this.variant[index]['sku2'][index2]['skuthree'].length;
        i++
      ) {
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'retailPrice'
        ] = this.variant[index]['sku2'][index2].retailPrice
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit'
        ] = this.variant[index]['sku2'][index2]['profit']
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit2'
        ] = this.variant[index]['sku2'][index2]['profit2']
      }
    },
    // 第三层的Retail
    changeRetail3(index, index2, index3, val) {
      this.variant[index]['sku2'][index2]['skuthree'][index3][
        'retailPrice'
      ] = val // 零售价
      this.variant[index]['sku2'][index2]['skuthree'][index3]['profit'] =
        this.variant[index]['sku2'][index2]['skuthree'][index3]['retailPrice'] -
        this.variant[index]['sku2'][index2]['skuthree'][index3]['costs'] // profit利润
      const profit2 =
        this.variant[index]['sku2'][index2]['skuthree'][index3]['profit'] / val
      this.variant[index]['sku2'][index2]['skuthree'][index3][
        'profit2'
      ] = profit2.toFixed(1)
    },

    changeCompare(index, val) {
      if (
        this.variant[index]['compareAtPrice'] <
        this.variant[index]['retailPrice']
      ) {
        this.variant[index]['compareAtPrice'] =
          this.variant[index]['compareAtPrice'] + 1
        this.$message.warn('Compare at price needs to be higher than Price.')
        return
      }
      this.variant[index]['compareAtPrice'] = val
      this.variant[index]['profit2'] =
        this.variant[index]['profit'] / this.variant[index]['retailPrice']
      for (let j = 0; j < this.variant[index]['sku2'].length; j++) {
        this.variant[index]['sku2'][j]['compareAtPrice'] = val
        this.variant[index]['sku2'][j]['profit2'] =
          this.variant[index]['sku2'][j]['profit'] /
          this.variant[index]['sku2'][j]['retailPrice']
        for (
          let i = 0;
          i < this.variant[index]['sku2'][j]['skuthree'].length;
          i++
        ) {
          this.variant[index]['sku2'][j]['skuthree'][i]['compareAtPrice'] = val
          this.variant[index]['sku2'][j]['skuthree'][i]['profit2'] =
            this.variant[index]['sku2'][j]['skuthree'][i]['profit'] /
            this.variant[index]['sku2'][j]['skuthree'][i]['retailPrice']
        }
      }
    },
    changeCompare2(index, index2, val) {
      if (
        this.variant[index]['sku2'][index2]['compareAtPrice'] <
        this.variant[index]['sku2'][index2]['retailPrice']
      ) {
        this.variant[index]['sku2'][index2]['compareAtPrice'] =
          this.variant[index]['sku2'][index2]['compareAtPrice'] + 1
        this.$message.warn('Compare at price needs to be higher than Price.')
        return
      }
      this.variant[index]['sku2'][index2]['compareAtPrice'] = val
      this.variant[index]['sku2'][index2]['profit2'] =
        this.variant[index]['sku2'][index2]['profit'] /
        this.variant[index]['sku2'][index2]['retailPrice']
      for (
        let i = 0;
        i < this.variant[index]['sku2'][index2]['skuthree'].length;
        i++
      ) {
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'compareAtPrice'
        ] = this.variant[index]['sku2'][index2].compareAtPrice
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit'
        ] = this.variant[index]['sku2'][index2]['profit']
        this.variant[index]['sku2'][index2]['skuthree'][i][
          'profit2'
        ] = this.variant[index]['sku2'][index2]['profit2']
      }
    },
    changeCompare3(index, index2, index3, val) {
      if (
        this.variant[index]['sku2'][index2]['skuthree'][index3][
          'compareAtPrice'
        ] <
        this.variant[index]['sku2'][index2]['skuthree'][index3]['retailPrice']
      ) {
        this.variant[index]['sku2'][index2]['skuthree'][index3][
          'compareAtPrice'
        ] =
          this.variant[index]['sku2'][index2]['skuthree'][index3][
            'compareAtPrice'
          ] + 1
        this.$message.warn('Compare at price needs to be higher than Price.')
        return
      }
      this.variant[index]['sku2'][index2]['skuthree'][index3][
        'compareAtPrice'
      ] = val

      this.variant[index]['sku2'][index2]['skuthree'][index3]['profit2'] =
        this.variant[index]['sku2'][index2]['skuthree'][index3]['profit'] /
        this.variant[index]['sku2'][index2]['skuthree'][index3]['retailPrice']
    },
    // 提交到shopify需要的参数
    async save(val) {
      console.log(val)
      console.log(this.pushShopCheck)
      this.fullscreenLoading = true
      const variantList = []
      for (let i = 0; i < this.variant.length; i++) {
        if (this.variant[i].id) {
          const param = {}
          param.compareAtPrice = this.variant[i].compareAtPrice
          param.id = this.variant[i].id
          param.retailPrice = this.variant[i].retailPrice
          variantList.push(param)
        } else if (this.variant[i].sku2[0].id) {
          for (let j = 0; j < this.variant[i].sku2.length; j++) {
            const param = {}
            param.compareAtPrice = this.variant[i].sku2[j].compareAtPrice
            param.id = this.variant[i].sku2[j].id
            param.retailPrice = this.variant[i].sku2[j].retailPrice
            variantList.push(param)
          }
        } else {
          for (let k = 0; k < this.variant[i].sku2.length; k++) {
            for (let l = 0; l < this.variant[i].sku2[k].skuthree.length; l++) {
              const param = {}
              param.compareAtPrice = this.variant[i].sku2[k].skuthree[
                l
              ].compareAtPrice
              param.id = this.variant[i].sku2[k].skuthree[l].id
              param.retailPrice = this.variant[i].sku2[k].skuthree[
                l
              ].retailPrice
              variantList.push(param)
            }
          }
        }
      }

      // for (let i = 0; i < this.variant.length; i++) {
      //   let param = {};
      //   param.compareAtPrice = this.variant[i].compareAtPrice;
      //   param.id = this.variant[i].id;
      //   param.retailPrice = this.variant[i].retailPrice;
      //   variantList.push(param);
      // }
      let imperial = false
      let mertic = false
      if (this.radioEditor === 1) {
        imperial = true
      } else if (this.radioEditor === 2) {
        mertic = true
      }
      const checkedImageIds = []
      this.images.forEach((item, index) => {
        if (item.isChecked) {
          checkedImageIds.push(item.id)
        }
      })
      const param = {
        // isAddImperial =
        checkedImageIds,
        customProductId: this.customProductId,
        description: this.editor.txt.html(),
        hiddenInStore: this.hideStore,
        isAddImperial: imperial,
        isAddMertic: mertic,
        mainImageId: this.mainImageId,
        shopifyCollectionId: this.collect,
        tags: this.tags.toString(),
        title: this.productTitle,
        variantList: variantList,
        vendor: this.vendor,
        personlizationSettingEnable: this.personlizationSettingEnable
      }
      if (this.CurrentShop && this.CurrentShop.shopType === 5) {
        param.description = this.description
      }
      if (this.personlizationSettingEnable) {
        param.personlizationSettingMaxlength = this.personlizationSettingMaxlength
        param.personlizationSettingName = this.personlizationSettingName
      }
      param.logisticsMethodId = this.logisticsMethodId
      param.serverLogisticsMethodId = this.serverLogisticsMethodId
      // 0不推送，1推送
      if (
        (this.pushShopCheck === true && this.CurrentShop.shopType === 1) ||
        (this.pushShopCheck === true && this.CurrentShop.shopType === 3) ||
          (this.pushShopCheck === true && this.CurrentShop.shopType === 5)
      ) {
        val = 1
      } else {
        val = 0
      }
      await productModify(param)
      if (val) {
        this.IsPushProductmutations(this.customProductId)
      }
      if (this.productType === this.enums.blankProductTypeEnum.DIY.value) {
        this.callNewPage({
          path: '/my-product',
          query: {
            type: '2'
          }
        })
      } else {
        this.callNewPage({
          path: '/my-product'
        })
      }
    },
    goCatalog() {
      this.$router.replace('/catalog')
    },
    stopClick() {}
  }
}
</script>
<style lang="scss">
.ant-tooltip-inner{
  background-color: #282828 !important;
  max-width: 676px;
}
.ant-tooltip-arrow::before{
  background-color: #282828 !important;
}
.description .ant-input{
  height: auto !important
}
</style>
<style lang="scss" scope>
@import "../../assets/css/mockup.scss";
.delivery-title{
  font-size: 16px;
  font-weight: 500;
  margin-top: 32px;
}
.top-title input{
  border: 1px solid #ddd
}
.descrip{
  height: 150px;
  overflow-y: scroll;
  background-color: #f2f4f5;
  padding: 8px;
}
.addview{
  margin-bottom: 10px;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  img{
    width: 18px !important;
    height: 18px !important;
  }
  span{
    margin-left: 4px;
  }
}
.top-title2{
  margin-top: -10px;
  margin-bottom: 12px;
  font-size: 12px;
  color: #565656;
}
.info-title{
  color: #333;
  display: inline-block;
  padding-bottom: 10px;
}
.isShowRadioEdit {
  margin-top: 20px;
}
.tag-title {
  margin-top: 20px;
  margin-bottom: 24px;
  font-weight: bold;
}
.mockups .editor {
  max-width: 652px;
  width: 100%;
  // height: 300px;
  height: 18.75vw;
  margin-bottom: 40px;
}
.mockups .a-btn {
  padding-bottom: 80px;
}
.mockups .a-btn a {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  width: 100px;
  text-align: center;
  float: right;
  background: dodgerblue;
}
.mockups {
  max-width: 1168px;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
}
.mockups .titles {
  margin-top: 30px;
  margin-bottom: 44px;
}
.mockups .titles img {
  float: left;
  width: 30px;
  height: 30px;
  margin-right: 15px;
  cursor: pointer;
}
.mockups .titles h2 {
  float: left;
  font-size: 33px;
  font-weight: bold;
}
.mockups .titles p {
  font-size: 12px;
  float: right;
}
.mockups .container {
  padding: 16px;
  text-align: left;
  background: white;
}
.mockups .container h4 {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 40px;
}
</style>
