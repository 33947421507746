<template>
  <div>
    <div v-for="(option) in options" :key="option[valueKey]" class="select " :class="value === option[valueKey] ? 'actived' : ''" @click="handleRadio(option)">
      <img :src="value === option[valueKey] ? require('@/assets/images/custom/radio2.png') : require('@/assets/images/custom/radio.png')" alt="">
      <span>{{ option[labelKey] }}</span>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: null
    },
    options: {
      default: () => []
    },
    valueKey: {
      default: 'value'
    },
    labelKey: {
      default: 'label'
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleRadio(item) {
      this.$emit('input', item[this.valueKey])
    }
  }
}
</script>
<style scoped lang="scss">
.select{
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  position: relative;
  border: 1px solid #D9D9D9;
  border-radius: 8px 8px 8px 8px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  z-index: 10;
  margin-top: 12px;
  cursor: pointer;
  &:hover{
    border: 1px solid #009EF7;
  }
  &.actived{
    border: 1px solid #009EF7;
  }
  img{
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }
}
</style>
