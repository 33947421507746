<template>
  <div>
    <div class="select" @click="isActived = !isActived">
      <span>{{ currentLabel }}</span>
      <img :src="isActived ? require('@/assets/images/custom/xia.png') : require('@/assets/images/custom/shang.png')" alt="">
      <div v-show="isActived" class="option">
        <div v-for="option in options" :key="option[valueKey]" class="option-li" @click="doSelect(option)">{{ option[labelKey] }}</div>
      </div>
    </div>
    <div class="error">{{ selectErrorText }}</div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      default: null
    },
    options: {
      default: () => []
    },
    valueKey: {
      default: 'value'
    },
    labelKey: {
      default: 'label'
    },
    isShowSelectError: {
      type: Boolean,
      default: false
    },
    selectErrorText: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isActived: false
    }
  },
  computed: {
    currentLabel() {
      if (this.value) {
        if (this.options && this.options.length) {
          for (const option of this.options) {
            if (option[this.valueKey] === this.value) {
              return option[this.labelKey]
            }
          }
        }
      }
      return 'Select'
    }
  },
  methods: {
    doSelect(v) {
      this.$emit('input', v[this.valueKey])
    }
  }
}
</script>
<style scoped lang="scss">
.select{
  width: 100%;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
  position: relative;
  border: 1px solid #D9D9D9;
  border-radius: 8px 8px 8px 8px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  z-index: 100;
  margin-top: 12px;
  img{
    width: 18px;
    height: 18px;
    margin-left: auto;
  }
  .option{
    position: absolute;
    top: 56px;
    left: 0;
    background-color: white;
    width: 100%;
    height: 200px;
    padding: 8px 10px;
    box-shadow: 0px 4px 17px 0px rgba(0,0,0,0.15);
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    overflow-y: scroll;
    &-li{
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      padding-left: 14px;
      border-radius: 4px;
      cursor: pointer;
      &:hover{
        background: #E8E8E8;
      }
    }
  }
}
 .error{
    color: #F56C6C;
    margin-top: 10px;
  }
</style>
