<template>
  <div>
    <div v-for="(item, index) in fittings2" :key="index" class="branding">
      <div class="brand-title">
        {{ item.productType }}
      </div>
      <div class="brand-boxs">
        <div class="brand-box">
          <div class="box-left">
            <img :src="item.mainImageUrl" alt="">
          </div>
          <div class="box-content">
            <div class="box-content-name">{{ item.title }}</div>
            <div v-if="item.isDesigned" class="box-content-price">Starting From:${{ item.price }}</div>
            <div v-else class="box-content-price2">Starting From:${{ item.price }}</div>
            <div v-if="item.isDesigned" class="box-content-del" @click="handleDel(item,index,'product')">Delete</div>
          </div>
        </div>
        <div v-if="item.designedUrl" class="brand-box">
          <div class="box-left">
            <img :src="item.designedUrl" alt="">
          </div>
          <div class="box-content">
            <div class="box-content-name">Shoes Box</div>
            <div class="box-content-del" @click="handleDel(item,index,'brand')">Delete</div>
          </div>
        </div>
        <div v-if="!item.isDesigned" class="edit-design" @click="handEdit(item)">Start design</div>
      </div>
    </div>
    <div class="nexts">
      <div class="next" @click="handleNext">Next ></div>
    </div>
  </div>
</template>
<script>
import { fittingsRemove } from '@/api/product'
import blankProductTypeCategoryEnum from '@/enums/blank_product/blankProductTypeCategoryEnum'
export default {
  props: {
    fittings: {
      type: Array,
      default: () => []
    },
    productType: {
      type: [Number, String]
    }
  },
  data() {
    return {
      fittings2: [],
      enums: {
        blankProductTypeCategoryEnum
      }
    }
  },
  watch: {
    fittings(value) {
      this.fittings2 = value
    }
  },
  created() {
    if (this.fittings) {
      this.fittings2 = this.fittings
    }
  },
  methods: {
    handleNext() {
      if (this.productType === this.enums.blankProductTypeCategoryEnum.value) {
        this.$emit('next', 0)
      } else {
        this.$emit('next', 4)
      }
    },
    async handleDel(item, index, type) {
      const { code, msg } = await fittingsRemove({ customProductId: this.$route.query.customProductId, fittingsCustomProductId: item.customProductId })
      if (code === 200) {
        this.$emit('refresh')
        this.$message.success('Success')
        // if (type === 'brand') {
        //   this.fittings2[index].designedUrl = ''
        //   this.fittings2[index].isDesigned = false
        // }
        // if (type === 'product') {
        //   this.fittings2.splice(index, 1)
        // }
      } else {
        this.$message.error(msg)
      }
    },
    handEdit(item) {
      this.$router.push({
        path: '/diyCustom',
        query: {
          customProductId: this.$route.query.customProductId,
          id: item.blankProductId,
          fitId: this.$route.query.productId,
          type: 'fittings'
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .branding{
    .brand-title{
      font-size: 16px;
      font-weight: bold;
      margin-top: 30px;
    }
    .brand-boxs{
      display: flex;
      align-items: center;
      background: #FAFAFA;
      padding: 20px;
      margin-top: 16px;
      border-radius: 12px 12px 12px 12px;
      .brand-box{
        display: flex;
        align-items: center;
        flex: 1;
        .box-left{
          width: 100px;
          height: 100px;
          object-fit: contain;
          img{
            width: 100px;
            height: 100px;
          }
        }
        .box-content{
          margin-left: 20px;
          &-name{
            font-size: 16px;
            font-weight: 400;
            color: #222;
          }
          &-price{
            font-size: 14px;
            color: #555555;
          }
          &-del{
            display: none;
            font-size: 14px;
            color: #FF5347;
            cursor: pointer;
          }
        }
        &:hover .box-content-del{
          display: block;
        }
        &:hover .box-content-price{
          display: none;
        }
      }
      .edit-design{
        width: 125px;
        height: 38px;
        line-height: 38px;
        background: #009EF7;
        color: white;
        text-align: center;
        border-radius: 4px 4px 4px 4px;
        cursor: pointer;
      }
    }
  }
  .nexts{
    display: flex;
    justify-content: right;
    .next{
      width: 89px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #009EF7;
      color: white;
      border-radius: 4px 4px 4px 4px;
      float: right;
      margin-top: 40px;
      cursor: pointer;
    }
  }
</style>
